//Init on screen detection
import OnScreen from 'onscreen';

const os = new OnScreen({
    tolerance: 0,
    debounce: 100
});

//Function to count from 0 to actual value of element.
const countIt = (counter, speed = 750) => {

    //Var
    const value = +counter.dataset.count;
    const data = +counter.innerText;
    const time = value / speed;

    //Set new value
    if (data < value) {
        counter.innerText = Math.ceil(data + time);
        setTimeout(countIt, 1, counter, speed);
    } else {
        counter.innerText = value;
    }

}

//DOM loaded
document.addEventListener("DOMContentLoaded", () => {

    //Set attribute data-count and reste to 0 .count elements.
    const counters = document.querySelectorAll('.pdw-count');
    counters.forEach(counter => {
        counter.dataset.count = counter.innerText;
        counter.innerText = 0;
    });

    //Trigger countIt when .count element is on screen.
    os.on('enter', '.pdw-count', (el, event) => {
        countIt(el, 750);
    });

});
