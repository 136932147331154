//Function to change menu visibility
const changeMenuVisibility = (action, id = 'pdw-mobile-menu') => {
    let el = document.getElementById(id);
    if (action === 'open') {
        el.classList.add('show');
    } else if (action === 'close') {
        el.classList.remove('show');
    } else if (action === 'toggle') {
        el.classList.toggle('show');
    }
}

//DOM loaded
document.addEventListener("DOMContentLoaded", () => {

    //Listen to close button
    document.querySelectorAll('.pdw-mobile-menu-close').forEach((el) => {
        el.addEventListener('click', () => {
            changeMenuVisibility('close');
        });
    });

    //Listen to open button
    document.querySelectorAll('.pdw-mobile-menu-open').forEach((el) => {
        el.addEventListener('click', () => {
            changeMenuVisibility('open');
        });
    });

    //Listen to toggle button
    document.querySelectorAll('.pdw-mobile-menu-toggler').forEach((el) => {
        el.addEventListener('click', () => {
            changeMenuVisibility('toggle');
        });
    });

});
