//Bootstrap component list: Alert, BaseComponent, Button, Carousel, Collapse, Dropdown, Modal, Offcanvas, Popover, ScrollSpy, Tab, Tooltip, Toast
import {Collapse} from 'bootstrap';

require('./utilities/class_enlarge.js');
require('./utilities/class_count.js');
require('./utilities/pdw_mobile_menu.js');
require('./utilities/pdw_drilldown_menu.js');
require('./utilities/pdw_carousel.js');
//require('./utilities/pdw_hide_link.js');
require('./utilities/pdw_scrolled.js');
require('./utilities/gallery-boostrap.js');
//require('./utilities/gallery-boostrap.js');

